import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { isUserLoggedIn, setAccessToken, setEmailIsVerified, setLoggedInUser } from '../lib/utils';
import { IdentityResponse, login } from '../lib/api';
import { ErrorBanner } from '@/components/errorBanner';
import Link from 'next/link';


export default function Login() {
    const router = useRouter();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [usernameErrorBannerText, setUsernameErrorBannerText] = useState('');
    const [passwordErrorBannerText, setPasswordErrorBannerText] = useState('');

    useEffect(() => {
        if (isUserLoggedIn()) {
            router.push('/');
        }
    }, [router]);


    const submit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        login(username, password)
            .then((response) => {
                if (response.status == 401) {
                    setPasswordErrorBannerText('Incorrect password!');
                }
                if (response.status == 404) {
                    setUsernameErrorBannerText('Username not found!');
                }
                if (response.status == 500) {
                    setUsernameErrorBannerText('Internal error! Please retry.');
                }
                return response.json();
            })
            .then((data: IdentityResponse) => {
                if (data.access) {
                    setAccessToken(data.access);
                    setEmailIsVerified(data.emailVerified);
                    setLoggedInUser(data.username);
                    let returnUrl = router.query.returnUrl || '/';
                    if (Array.isArray(returnUrl)) {
                        returnUrl = returnUrl[0];
                    }
                    router.push(returnUrl);
                }
            });
    };


    return (
        <div className="h-screen m-auto mx-24 flex">
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <h1 className="mt-8 text-5xl font-bold  tracking-tight text-gray-900">
                            Free tier keepalive for render.com®
                        </h1>
                        <p className="text-sm">Keep your services up <span className='font-bold underline text-emerald-500'>FOR FREE</span>, without the hassle.</p>
                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Sign in to your account
                        </h2>
                        <p className="mt-2 text-sm leading-6 text-gray-500">
                            or{' '}
                            <Link className="font-semibold text-indigo-600 hover:text-indigo-500" href="/register">
                                create an account!
                            </Link>
                        </p>
                    </div>

                    <div className="mt-10">
                        <form>
                            <div className="space-y-6">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="username"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                                setUsernameErrorBannerText('');
                                                setPasswordErrorBannerText('');
                                            }}
                                        />
                                    </div>
                                    <ErrorBanner errorText={usernameErrorBannerText} />
                                </div>

                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                setUsernameErrorBannerText('');
                                                setPasswordErrorBannerText('');
                                            }}
                                        />
                                        <ErrorBanner errorText={passwordErrorBannerText} />
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="text-sm leading-6">
                                        <Link
                                            href="/reset-password"
                                            className="font-semibold text-indigo-600 hover:text-indigo-500"
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={(e) => submit(e)}
                                    >
                                        Sign in
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="mt-10">
                            <div className="relative">
                                <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true"
                                ></div>
                            </div>

                            <div className="mt-6 grid grid-cols-2 gap-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                />
            </div>
        </div>
    );
}
